import { format, isToday } from 'date-fns';
import { pl } from 'date-fns/locale';

export const fullDateFormat = (date: string): string =>
  format(new Date(date), 'do MMMM yyyy', {
    locale: pl,
  });

export const fullDateWithTimeFormat = (date: Date): string =>
  isToday(date)
    ? `dziś, ${format(date, 'HH:mm')}`
    : format(date, 'yyyy-MM-dd, HH:mm');
